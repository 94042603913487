@charset "UTF-8";

/*-----------------------------------------------
 imabari
-----------------------------------------------*/

.shopHeader {
    &.shopHeader--imabari {
        .header__keyVs {
            background:
            url(../images/imabari/header_keyVs-flame.png) no-repeat center / cover,
            url(../images/imabari/header_keyVs.jpg) no-repeat center / cover;
        }
    }
}

.imabari {
    &.imabari--index {

        .imabari__concept {

            .shopContent {
                position: relative;
            }

            .conceptTop {
                display: flex;
                justify-content: space-around;

                @include mb {
                    display: inherit;
                    margin-bottom: 15%;
                }

                .catchCopy {
                    display: flex;

                    .catchCopy__img {
                        padding-right: 40px;

                        @include mb {
                            padding-right: 10%;
                        }

                        img {
                            max-width: 90px;

                            @include mbTab {
                                max-width: 48px;
                            }
                        }
                    }

                    .catchCopy__txt {
                        margin-top: 40px;

                        @include mbTab {
                            margin-top: 5%;
                        }
                    }
                }

                .cuisine {
                    @include tab {
                        padding-right: 40px;
                    }

                    @include mb {
                        margin-top: -30px;
                    }

                    // ブリと鯛
                    .cuisine__img {
                        @include mb {
                            text-align: right;
                            padding-right: 5%;
                        }

                        img {
                            max-width: 480px;

                            @include mbTab {
                                max-width: 250px;
                            }
                        }
                    }

                    .cuisine__name {
                        text-align: right;
                        @include pc {
                            margin-top: -30px;
                        }

                        @include mb {
                            margin-top: 0;
                            padding-right: 5%;
                        }

                        img {
                            width: auto;
                            max-height: 53px;

                            @include mbTab {
                                max-height: 40px;
                            }
                        }
                    }
                }
            } //conceptTop

            .conceptBtm {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                @include tab {
                    justify-content:space-around;
                }

                @include mb {
                    display: block;
                    position: relative;
                    margin-bottom: 5%;
                }

                .cuisine {
                    display: flex;
                    align-items: center;

                    @include mb {
                        display: block;
                    }

                    // 媛の三大将
                    .cuisine__img {
                        margin-right: 30px;

                        @include mb {
                            display: inline-block;
                            margin: 0 0 5% 0;
                        }

                        img {
                            max-width: 510px;

                            @include mbTab {
                                max-width: 255px;
                            }
                        }
                    }

                    .cuisine__box {
                        @include mb {
                            padding-left: 5%;
                        }

                        .cuisine__boxName {
                            margin-bottom: 20px;

                            @include mb {
                                margin-bottom: 5%;
                            }

                            img {
                                width: auto;
                                max-height: 22px;

                                @include mbTab {
                                    max-height: 18px;
                                }
                            }
                        }
                    }
                }

                .catchCopy {
                    margin-bottom: 30px;
                    padding-right: 40px;

                    @include mb {
                        display: inline-block;
                        margin-bottom: 0;
                        padding-right: 5%;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }

                    img {
                        max-width: 120px;
                        @include mbTab {
                            max-width: 64px;
                        }
                    }
                }
            } //conceptBtm
        }

        .shop__menu--imabari {
            .menu {
                .menu__deco {
                    position: absolute;
                    z-index: 1;

                    &.menu__deco--leftTop1 {
                        top: -40px;

                        @include mbTab {
                            top: -10px;
                        }

                        img {
                            max-width: 220px;

                            @include mbTab {
                                max-width: 110px;
                            }
                        }
                    }

                    &.menu__deco--leftTop2 {
                        top: 70px;
                        left: -20px;

                        @include mbTab {
                            top: 45px;
                            left: 0;
                        }

                        img {
                            max-width: 180px;

                            @include mbTab {
                                max-width: 90px;
                            }
                        }
                    }

                    &.menu__deco--rightTop {
                        top: -20px;
                        right: 10px;

                        @include mbTab {
                            top: -10px;
                            right: 5px;
                        }

                        img {
                            max-width: 220px;

                            @include mbTab {
                                max-width: 110px;
                            }
                        }
                    }

                    &.menu__deco--leftBtm {
                        bottom: -60px;
                        left: 4px;

                        @include mbTab {
                            bottom: -30px;
                        }

                        img {
                            max-width: 220px;

                            @include mbTab {
                                max-width: 110px;
                            }
                        }
                    }

                    &.menu__deco--rightBtm1 {
                        bottom: 10px;
                        right: -40px;

                        @include mbTab {
                            bottom: 20px;
                            right: 0;
                        }

                        img {
                            max-width: 120px;

                            @include mbTab {
                                max-width: 60px;
                            }
                        }
                    }

                    &.menu__deco--rightBtm2 {
                        bottom: -80px;
                        right: 40px;

                        @include mbTab {
                            bottom: -40px;
                            right: 20px;
                        }

                        img {
                            max-width: 200px;

                            @include mbTab {
                                max-width: 100px;
                            }
                        }
                    }
                }
            }
        }

        .imabari__info {

            .infoTop {
                .shopContent {
                    padding: 100px 0 50px;

                    @include mbTab {
                        padding: 16% 5% 10%;
                    }

                    @include tab {
                        padding: 10% 5% 5%;
                    }
                }

                .infoTop__deco--outer {
                    position: relative;

                    .infoTop__deco {
                        position: absolute;
                        z-index: 1;

                        &.infoTop__deco--leftTop1 {
                            position: absolute;
                            top: -40px;
                            right: 0;

                            @include tab {
                                right: 10px;
                            }

                            img {
                                max-width: 220px;

                                @include tab {
                                    max-width: 130px;
                                }

                                @include mb {
                                    display: none;
                                }
                            }
                        }

                        &.infoTop__deco--leftTop2 {
                            position: absolute;
                            top: 120px;
                            right: -40px;

                            @include tab {
                                top: 60px;
                                right: -20px;
                            }

                            img {
                                max-width: 160px;

                                @include tab {
                                    max-width: 90px;
                                }

                                @include mb {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .infoTop__detail {
                    overflow: hidden;

                    .infoTop__detailTtl {
                        float: left;
                        clear: both;
                    }

                    .infoTop__detailData {
                        margin-bottom: 20px;
                        padding-left: 90px;

                        @include mbTab {
                            padding-left: 70px;
                        }

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .infoMap {
                .shopContent {
                    padding: 50px 0 80px;

                    @include mbTab {
                        padding: 10% 5% 20%;
                    }

                    @include tab {
                        padding: 5% 5% 10%;
                    }
                }

                .infoMap__gmap {
                    width: 100%;
                    height: 460px;
                    position:relative;

                    @include mbTab {
                        height: 300px;
                    }
                }

                .infoMap__btn {
                    display: inline-block;
                    margin-bottom: 20px;
                    padding: 12px 26px;
                    border: 1px solid #fff;
                    border-radius: 4px;
                    background-color: transparent;

                    @include mb {
                        width: 100%;
                        margin-bottom: 10%;
                        padding: 10px 0;
                        text-align: center;
                    }

                    &:hover {
                        opacity: 1;
                        background-color: rgba(#fff, 0.2);
                    }
                }
            }
        }
    }
}

.shopFooter {
    &.shopFooter--imabari {

        .shopFooter__board {
            background: url(../images/imabari/footer_bg-imabari.png) repeat center;

            .shopFooter__board--boderTop {
                background: url(../images/imabari/footer_boderTop.png) repeat center;
            }

            .shopFooter__board--boderBtm {
                background: url(../images/imabari/footer_boderBtm.png) repeat center;
            }
        }
    }
}
