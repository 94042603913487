@charset "UTF-8";

/*-----------------------------------------------
 partner
-----------------------------------------------*/

.header {
    &.header--ptr {
        height: 500px;
        background-image: url(../images/header_keyVs-ehime.jpg);

        @include mbTab {
            height: 200px;
        }

        @include tab {
            height: 300px;
        }

        .header__ptrCatch {
            max-width: 900px;
            margin: 150px auto 0;

            @include mbTab {
                margin: 40px auto 0;
                padding-left: 5%;
            }

            @include tab {
                margin: 50px auto 0;
                padding-left: 5%;
            }

            img {
                max-width: 160px;

                @include mbTab {
                    max-width: 80px;
                }

                @include tab {
                    max-width: 120px;
                }
            }
        }
    }
}

.ptr {
    &.ptr--index {

        .ptr__top {
            .contentTop {
                padding-bottom: 0;
            }
            .ptr__topTxt {
                margin-bottom: 30px;

                @include mbTab {
                    margin-bottom: 10%;
                }

                @include tab {
                    margin-bottom: 5%;
                }
            }

            .ptr__topBox {
                &.topBox {
                    position: relative;

                    .topBox__img {
                        width: 100%;
                        display: flex;
                        justify-content: space-around;

                        .topBox__img--map {
                            @include mbTab {
                                order: 2;
                                margin-top: 10%;
                            }

                            img {
                                max-width: 598px;
                            }
                        }

                        .topBox__img--catch {
                            @include mbTab {
                                padding-left: 2%;
                                order: 1;
                            }

                            img {
                                max-width: 160px;
                            }
                        }
                    }

                    .topBox__note {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        max-width: 350px;
                        margin: 30px 0;

                        @include mbTab {
                            position: inherit;
                            margin: 10% 0 0;
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        .ptr__intro {
            position: relative;

            .introCard--wrap {
                .introCard {
                    margin-bottom: 50px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    @include mb {
                        margin-bottom: 15%;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }

                    .introCard__ttl {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;

                        @include mb {
                            display: block;
                            margin-bottom: 5%;
                        }

                        .introCard__ttlLogo {
                            margin-right: 10px;
                            display: flex;

                            @include mb {
                                margin-right: 0;
                            }

                            img {
                                max-width: 60px;
                                margin-right: 10px;

                                @include mb {
                                    max-width: 34px;
                                    margin: 0 5px 5% 0;
                                }
                            }
                        }

                        .introCard__ttlTxt {
                            font-size: 2.4rem;

                            @include mb {
                                font-size: 2.0rem;
                            }
                        }
                    }

                    .introCard__cont {
                        display: flex;
                        align-items: center;

                        @include mb {
                            display: block;
                        }

                        .introCard__contImg {
                            width: 44.5%;

                            @include mb {
                                width: 100%;
                                margin-bottom: 5%;
                            }

                            .img {
                                width: 100%;
                                max-width: 400px;
                                height: 247px;
                                background: no-repeat center / cover;

                                @include tab {
                                    max-width: 300px;
                                    height: 185px;
                                }

                                @include mb {
                                    max-width: 100%;
                                    height: 200px;
                                }
                            }
                        }

                        .introCard__contBox {
                            &.contBox {
                                width: 55.5%;
                                padding-left: 5%;

                                @include mb {
                                    width: 100%;
                                    padding-left: 0;
                                }

                                .contBox__ttl {
                                    margin-bottom: 20px;
                                    font-size: 2.2rem;

                                    @include mb {
                                        margin-bottom: 5%;
                                        font-size: 2.0rem;
                                    }

                                    .contBox__ttlMark {
                                        display: block;
                                        margin-bottom: 4px;
                                        font-size: 1.0rem;
                                    }
                                }

                                .contBox__txt {
                                    margin-bottom: 30px;

                                    @include mb {
                                        margin-bottom: 10%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}
