@charset "UTF-8";

/*-----------------------------------------------
 company
-----------------------------------------------*/

.header {
    &.header--company {
        background-image: url(../images/header_keyVs-about.jpg);
    }
}

.company {
    &.company--index {

        .company__msg {
            position: relative;

            .company__msgImg {
                float: left;
                max-width: 50%;
                padding-right: 30px;

                @include tab {
                    padding-right: 4%;
                }

                @include mb {
                    float: none;
                    max-width: 100%;
                    margin-bottom: 10%;
                    padding-right: 0;
                }

                img {
                    max-width: 425px;
                }
            }

            .company__msgTxt {
                min-width: 50%;
                margin-bottom: 30px;

                @include mb {
                    min-width: none;
                    margin-bottom: 10%;
                }
            }

            .company__msgName {
                text-align: right;

                img {
                    max-width: 238px;
                }
            }
        }

        .company__info {
            position: relative;
            background: $color_2;

            .company__infoDef {
                &.infoDef {
                    max-width: 560px;
                    margin: 0 auto;

                    .infoDef__ttl {
                        float: left;
                        clear: both;
                        padding: 15px 0 15px 15px;

                        @include mbTab {
                            padding: 5% 0 5% 5%;
                        }
                    }

                    .infoDef__data {
                        padding: 15px;
                        padding-left: 120px;
                        border-bottom: 1px solid $borderColor;

                        @include mbTab {
                            padding: 5%;
                            padding-left: 30%;
                        }
                    }
                }
            }
        }

        .company__shop {
            position: relative;

            .shop {
                margin-bottom: 40px;

                @include mbTab {
                    margin-bottom: 10%;
                }

                @include tab {
                    margin-bottom: 20px;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                a {
                    display: flex;
                    align-items: center;

                    @include mbTab {
                        display: block;
                    }

                    &:hover {
                        opacity: 0.9;
                        transform: translateY(-3px);
                        box-shadow:0px 8px 10px -2px rgba(#858585, 0.4);
                        transition: .3s;
                    }

                    .shop__img {
                        width: 40%;
                        height: 200px;
                        background: no-repeat center / cover;

                        @include mbTab {
                            width: 100%;
                            margin-bottom: 5%;
                        }

                        @include tab {
                            height: 260px;
                            margin-bottom: 0;
                        }
                    }

                    .shop__box {
                        width: 60%;
                        padding: 20px 20px 20px 40px;

                        @include mbTab {
                            width: 100%;
                            padding: 0;
                        }

                        @include tab {
                            padding: 30px 20px;
                        }

                        .shop__boxTtl {
                            display: flex;
                            align-items: flex-start;
                            margin-bottom: 30px;

                            @include mb {
                                display: block;
                                margin-bottom: 5%;
                            }

                            img {
                                width: auto;
                                max-height: 54px;

                                @include mbTab {
                                    max-height: 40px;
                                }
                            }

                            &.shop__boxTtl--kokyo {
                                span {
                                    background: $color_1;
                                }
                            }

                            &.shop__boxTtl--imabari {
                                span {
                                    background: $keyColor;
                                }
                            }

                            &.shop__boxTtl--san {
                                span {
                                    background: $color_san;
                                }
                            }

                            span {
                                display: block;
                                width: 88px;
                                height: 32px;
                                margin-right: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 3px;
                                color: $baseColor;

                                @include mb {
                                    margin-right: 0;
                                    margin-bottom: 5%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .company__map {
            position: relative;

            .gmap--company {
                width: 100%;
                height: 460px;
                position:relative;

                @include mbTab {
                    height: 300px;
                }
            }
        }
    }
}
