@charset "UTF-8";

/*-----------------------------------------------
 all
-----------------------------------------------*/
html {
    height: 100%;
    min-height: 100%;
    font-size: $fontsizeBase;
    background: $baseColor;
    overflow: auto;
}

body {
    min-height: 100%;
    margin: 0 auto;
    padding: 0;
    line-height: 1.8;
    letter-spacing: 0.08em;
    color: $fontColor;

    @include fontFamilyMetaGothic();

    @include pc {
        font-size: 1.4rem;
    }

    @include tab {
        font-size: 1.4rem;
        overflow-x: hidden;
    }

    @include mb {
        font-size: 1.4rem;
        overflow-x: hidden;
    }

    &.shopBody {
        color: #fff;

        a {
            color: #fff;
        }

        &.shopBody--kokyo {
            background:
            url(/assets/images/kokyo/header_logo-bar.png) repeat-x center 20px,
            #392d28 url(/assets/images/kokyo/bg-kokyo.jpg) repeat center top / contain;

            @include mbTab {
                background:
                url(/assets/images/kokyo/header_logo-bar.png) repeat-x center 15px,
                #392d28 url(/assets/images/kokyo/bg-kokyo.jpg) repeat center top / contain;
            }
        }

        &.shopBody--imabari {
            background:
            url(/assets/images/imabari/header_logo-bar.png) repeat-x center 20px,
            #2e3f36 url(/assets/images/imabari/bg-imabari.jpg) repeat center top / 90%;

            @include mbTab {
                background:
                url(/assets/images/imabari/header_logo-bar.png) repeat-x center 15px,
                #2e3f36 url(/assets/images/imabari/bg-imabari.jpg) repeat center top / 90%;
            }
        }

        &.shopBody--san {
            background:
            url(/assets/images/san/header_logo-bar.png) repeat-x center 0px,
            $color_san url(/assets/images/san/bg-san.jpg) repeat center top / 90%;

            @include mbTab {
                background:
                url(/assets/images/san/header_logo-bar.png) repeat-x center -5px,
                $color_san url(/assets/images/san/bg-san.jpg) repeat center top / 90%;
            }
        }
    }
}

a {
    color: $linkColor;
    text-decoration: none;

    &:hover {
        opacity: .8;
        transition: .3s;
    }
}

img {
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
}

/*-----------------------------------------------
generic
-----------------------------------------------*/
/*---- block  ----*/
.dsn {
    display: none !important;
}

.bdn {
    border: none !important;
}

/*--- clearfix ---*/
.clear {
    clear: both;
}

.cf {
    display: block;

    &::after {
        clear: both;
        visibility: hidden;
        display: block;
        height: 0;
        content: "";
    }
}

/*---- display  ----*/
.mbOn {
    @include pc {
        display: none;
    }
}

.mbOff {
    @include mbTab {
        display: none;
    }
}

.mboff2 {
    @include mb {
        display: none;
    }
}

/*---- height  ----*/
.lh_0 {
    line-height: 0;
}

/*-----------------------------------------------
 component
-----------------------------------------------*/
@import "components";

/*-----------------------------------------------
common
-----------------------------------------------*/
/*---- .header  ----*/
.header {
    position: relative;
    width: 100%;
    min-height: 200px;
    background: no-repeat center center / cover;

    @include mbTab {
        min-height: 120px;

        &:before {
            z-index: 0;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color:rgba(0,0,0,0.3);
        }
    }

    @include tab {
        min-height: 140px;
    }

    // 全ページ共通パーツ
    .header__gnav {
        position: absolute;
        top: 0;
        width: 100%;

        .pcGnav {
            max-width: 900px;
            margin: 30px auto 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include mbTab {
                display: none;
            }

            .pcGnav__logo {
                max-width:  220px;

                @include mbTab {
                    max-width: 226px;
                }
            }

            .pcGnav__gnav {
                .pcGnav__gnavList {
                    display: flex;

                    .pcGnav__gnavMenu {
                        padding-right: 30px;

                        &:last-of-type {
                            padding-right: 0;
                        }

                        img {
                            width: auto;
                            max-height: 20px;
                        }
                    }
                }
            }
        }

        .mbGnav {
            padding: 5%;
            // @include pc {
            //     display: none;
            // }
            .mbGnav__gnav {
                padding: 0 5%;
                background: transparent;

                .mbGnav__gnavMenu {
                    &.gnavMenu {
                        border-bottom: 1px solid $baseColor;

                        a {
                            display: block;
                            padding: 5% 0;
                        }

                        .gnavMenu__ttl {
                            padding-left: 2%;
                            width: auto;
                            max-height: 20px;
                        }
                    }

                    // 店舗紹介
                    &.gnavMenu--shop {
                        .adjustment {
                            padding-top: 5%;
                        }

                        a {
                            display: block;
                            padding: 5% 0 5% 5%;

                            .gnavMenu__ttl {
                                width: auto;
                                max-height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

    .header__boderBtm {
        position: absolute;
        bottom: -18px;
        width: 100%;
        height: 25px;
        background: url(/assets/images/header_img-boderBtm.png) repeat-x center;
    }

    // 下層ページの共通パーツ
    .header__lowerPage {
        .header__lowerPageTtl {
            position: absolute;
            right: 5%;
            bottom: 20px;

            img {
                width: auto;
                max-height: 50px;
            }
        }
    }

}

.shopHeader {

    .header__top {
        width: 100%;
        height: 170px;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;

        @include mbTab {
            height: 120px;
            padding-top: 15px;
        }
    }

    .header__topLogo {
        z-index: 1;
        width: 50%;

        @include mbTab {
            width: 70%;
            padding-left: 2%;
        }

        @include tab {
            width: 70%;
            padding-left: 2%;
        }
    }

    .header__topReserve {
        &--pc {
            width: 40%;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mbTab {
                display: none;
            }
            .header__topReserve--left {
                .header__topReserve__ttl {
                    max-width: 206px;
                }

                .header__topReserve__tel {
                    margin-top: .75rem;
                    max-width: 204px;

                    a {
                        &:hover {
                            opacity: .6;
                        }
                    }
                }
            }

            .header__topReserve--right {
                margin: .75rem 0 0 30px;

                .header__topReserve__web {
                    a {
                        padding: 1.5rem 2rem;
                        border: 1px solid $kbd-color;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:hover {
                            opacity: 1;
                            background: rgba(255, 255, 255, 0.2);
                        }

                        img {
                            max-width: 103px;
                        }
                    }
                }
            }
        }

        &--mb {
            margin-top: 22px;
            display: none;

            @include mbTab {
                display: block;
                width: 30%;
                padding:  0 2.5%;
            }

            .header__topReserveItems {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .header__topReserveItem {
                    img {
                        @include tab {
                            max-width: 42px;
                        }
                    }

                    & + .header__topReserveItem {
                        margin-left: 1rem;
                    }
                }
            }
        }
    }

    .header__keyVs,
    .header__keyVs--sp {
        width: 100%;
        height: 480px;
        margin-top: -40px;

        @include tab {
            height: 380px;
        }

        @include mb {
            height: 220px;
            margin-top: -30px;
        }
    }
}

/*---- .footer  ----*/
.footer {
    .content {
        @include tab {
            padding: 7% 0 5%;
        }

        @include mb {
            padding: 15% 0 10%;
        }
    }

    .footer__conts {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 30px;

        @include mbTab {
            display: inherit;
            display: none;
        }

        .footer__contsLogo {
            img {
                max-width: 176px;
            }
        }

        .footer__contsNav {
            &.contsNav {
                .contsNav__list {
                    display: flex;

                    .contsNav__listItem {
                        a {
                            &:hover {
                                opacity: 0.5;
                            }
                        }

                        span {
                            padding: 0 15px;
                        }

                        &:last-of-type {
                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer__copylight {
        text-align: right;
        color: $color_5;

        @include mbTab {
            text-align: center;
        }
    }
}

.shopFooter {

    .shopFooter__board {
        position: relative;

        .shopFooter__board--boderTop {
            position: absolute;
            top: -10px;
            width: 100%;
            height: 46px;
        }

        .shopFooter__board--boderBtm {
            position: absolute;
            bottom: -40px;
            width: 100%;
            height: 66px;
        }

        // フッターボードの中身
        .board {
            padding: 60px 0;
            position: relative;

            @include mbTab {
                padding: 15% 5%;
            }

            @include tab {
                padding: 7% 5%;
            }

            .board__deco {
                position: absolute;
                z-index: 1;

                &.board__deco--rightTop {
                    top: -40px;
                    right: -30px;

                    @include mbTab {
                        top: -20px;
                        right: 0;
                    }

                    img {
                        max-width: 220px;

                        @include mbTab {
                            max-width: 110px;
                        }
                    }
                }

                &.board__deco--leftBtm {
                    bottom: -50px;
                    left: -30px;

                    @include mbTab {
                        bottom: -25px;
                        left: 0;
                    }

                    img {
                        max-width: 280px;

                        @include mbTab {
                            max-width: 140px;
                        }
                    }
                }
            }

            .board__nav {
                display: flex;
                margin-bottom: 50px;
                max-width: 90%;

                @include mbTab {
                    margin-bottom: 10%;
                    max-width: 100%;
                }

                @include tab {
                    margin-bottom: 5%;
                }

                .board__navShop {
                    &.navShop {
                        .navShop__ttl {
                            margin-bottom: 10px;

                            img {
                                max-width: 80px;

                                @include mbTab {
                                    max-width: 64px;
                                }
                            }
                        }
                    }
                }

                .board__navSite {
                    &.navSite {
                        display: flex;
                        align-items: flex-end;

                        @include mbTab {
                            display: block;
                        }
                    }
                }
            }

            .board__logo {
                clear: both;
                text-align: right;

                img {
                    width: auto;
                    max-height: 58px;

                    @include mbTab {
                        max-height: 40px;
                    }
                }
            }
        }
    }

    .shopFooter__copyright {
        font-size: 1.0rem;
        text-align: center;
        margin: 50px;
    }
}


/*---- 求人情報  ----*/
.recruit {
    position: relative;
    background: $color_2;

    .recruit__bnr {
        .bnr--pc {
            @include mb {
                display: none;
            }
        }
        .bnr--sp {
            @include pc {
                display: none;
            }

            @include tab {
                display: none;
            }
        }
    }
}


/*---- その他各店舗 ----*/
.shop__slider {
    margin-bottom: 200px;

    @include tab {
        margin-bottom: 15%;
    }

    @include mb {
        margin-bottom: 25%;
    }

    .slider {
        .slider__list {
            position: relative;
            max-width: 100%;

            .slider__listItem {
                height: 500px;
                background: #000 no-repeat center / cover;

                @include tab {
                    height: 350px;
                }

                @include mb {
                    height: 250px;
                }
            }

            .slider__listPrev,
            .slider__listNext {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;

                img {
                    max-width: 20px;

                    @include mbTab {
                        max-width: 14px;
                    }
                }
            }

            .slider__listPrev {
                left: 5%;
            }

            .slider__listNext {
                right: 5%;
            }

            // dotsのカラー設定
            .slick-dots {
                bottom: -30px;

                li {
                    button:before {
                        color: #fff;
                    }
                }
            }
        }

        .slider__nav {
            position: relative;
            max-width: 980px; // サイトの幅
            margin: 0 auto;

            .slider__navList {
                position: absolute;
                top: -50px;
                right: 0;
                max-width: 60%;

                .slider__navListItem {
                    width: 170px;
                    height: 120px;
                    margin: 10px;
                    background: #000 no-repeat center / cover;
                    border: 2px solid #fff;
                    border-radius: 4px;

                    img {
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}

.shop__menu {
    .menu {
        padding-bottom: 80px;

        @include tab {
            padding-bottom: 5%;
        }

        @include mb {
            padding-bottom: 15%;
        }

        /*-----------------------------------------------
        menu 共通設定
        -----------------------------------------------*/
        .menu__ttl {
            margin: 50px 0 40px;
            text-align: center;

            @include mbTab {
                margin: 15% 0 10%;
            }

            @include tab {
                margin: 40px 0 20px;
            }

            .menu__ttlImg {
                margin-bottom: 20px;

                @include mb {
                    margin-bottom: 5%;
                }

                img {
                    width: auto;
                    max-height: 46px;

                    @include mbTab {
                        max-height: 28px;
                    }
                }
            }

            .menu__ttlImg--assist {
                line-height: 0;

                img {
                    width: auto;
                    max-height: 14px;

                    @include mbTab {
                        max-height: 8px;
                    }
                }
            }
        }

        .menu__cat {
            margin-bottom: 50px;
            overflow: hidden;

            @include mb {
                margin-bottom: 15%;
            }

            .menu__catTtl {
                margin-bottom: 20px;

                @include mb {
                    margin-bottom: 5%;
                }

                img {
                    width: auto;
                    max-height: 28px;

                    @include mbTab {
                        max-height: 22px;
                    }
                }
            }

            .menu__catList {
                &.catList {
                    overflow: hidden;
                    line-height: 1.4;

                    .catList__name {
                        float: left;
                        clear: both;

                        @include mb {
                            float: none;
                        }

                        // 各料理のコメント
                        .catList__name--assist {
                            display: block;
                            margin-bottom: 14px;
                            padding-left: 2em;
                            font-size: $sizeS;
                            color: rgba(#ffffff, 0.7);

                            @include mb {
                                margin-bottom: 0;
                                padding-left: 1em;
                            }
                        }

                        // 各料理のリスト（種類）
                        .catList__nameType {
                            margin: 4px 0 14px;
                            padding-left: 2em;
                            font-size: $sizeS;

                            @include mb {
                                margin: 0;
                                padding-left: 1em;
                            }
                        }
                    }

                    .catList__price {
                        color: #ecd9c3;
                        float: right;
                        margin-bottom: 10px;

                        @include mb {
                            margin-bottom: 5%;
                        }
                        .catList__price--tax {
                            font-size: $sizeSS;
                        }
                    }
                }
            }

            // 各カテゴリのコメント
            .menu__catNote {
                margin-top: 10px;
                padding-left: 1em;
                font-size: $sizeS;
                color: #e3c2bf;

                @include mb {
                    margin-top: 0;
                    padding-left: 0;
                }
            }
        }


        /*-----------------------------------------------
         menu 個別設定
        -----------------------------------------------*/
        .menu__cat {

            &.aLaCarte {
                .catList__name {
                    font-family: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
                }
            }

            &.drink {
                padding-right: 20px;

                @include mbTab {
                    padding-right: 0;
                }

                .drink__ttl {
                    img {
                        max-width: 95px;

                        @include mbTab {
                            max-width: 80px;
                        }
                    }
                }
            }

            &.lunch {
                padding-left: 20px;

                @include mbTab {
                    padding-left: 0;
                }

                .lunch__note {
                    color: #e3c2bf;
                }
            }
        }
    }
}
