@charset "UTF-8";

/*-----------------------------------------------
 home
-----------------------------------------------*/

.header {
    &.header--home {
        .header__slider {
            .slider {
                .slider__list {
                    margin-bottom: 0;

                    .slider__listItem {
                        height: 500px;
                        background: no-repeat center / cover;

                        @include mbTab {
                            height: 400px;
                        }
                    }
                }

                .slick-dots {
                    bottom: 20px;

                    button:before {
                        color: #fff;
                    }
                }
            }

            .logo {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);

                img {
                    max-width: 266px;
                }
            }
        }
    }
}

.home {
    &.home--index {

        .home__shop {
            position: relative;

            .home__shopList {
                &.shopList {
                    display: flex;
                    justify-content: space-between;

                    @include tab {
                        margin-right: -5%;
                    }

                    @include mb {
                        display: block;
                        text-align: center;
                    }

                    .shopList__item {
                        width: 32%;

                        @include tab {
                            width: 100%;
                            padding-right: 5%;
                        }

                        @include mb {
                            width: 100%;
                            margin-bottom: 10%;
                        }

                        &:last-of-type {
                            @include mbTab {
                                margin-bottom: 0;
                            }
                        }

                        a {
                            &:hover {
                                opacity: 0.9;
                            }

                            img {
                                width: 100%;
                                height: auto;
                                max-width: 434px;
                                box-shadow:0px 5px 6px 0px rgba(#858585, 0.5);

                                &:hover {
                                    transform: translateY(-3px);
                                    box-shadow:0px 5px 17px -6px rgba(#858585, 0.9);
                                    transition: .3s;
                                }
                            }
                        }
                    }
                }
            }
        }

        .home__news {
            position: relative;
            background: $color_2;

            .home__newsBoard {
                .newsBoard {
                    .newsBoard__def {
                        .newsBoard__defData {
                            float: left;
                            clear: both;
                            padding-right: 20px;
                            color: $color_3;

                            @include mbTab {
                                padding-right: 5%;
                            }
                        }

                        .newsBoard__defTtl {
                            a {
                                color: $baseColor;
                                display: block;

                                &:hover {
                                    color: $color_3;
                                    opacity: 1;
                                }
                            }
                            margin-bottom: 20px;
                            padding-bottom: 10px;
                            border-bottom: 1px solid #75837b;

                            @include mbTab {
                                margin-bottom: 5%;
                                padding-bottom: 5%;
                            }

                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .home__ptr {
            position: relative;

            .home__ptrImg {
                &.ptrImg {
                    width: 100%;
                    height: 400px;
                    background: $keyColor url(../images/top_bg-ehime.jpg) no-repeat center;
                    background-size: cover;

                    @include mbTab {
                        height: 250px;
                    }

                    .ptrImg__catchCopy {
                        height: 100%;
                        display: flex;
                        align-items: center;

                        @include mbTab {
                            padding: 0 5%;
                        }

                        img {
                            max-width: 160px;

                            @include mbTab {
                                max-width: 100px;
                            }
                        }
                    }
                }
            }

            .home__ptrConts {
                .content {
                    padding:  60px 0 60px;

                    @include mbTab {
                        padding:  10% 5%;
                    }
                }

                &.ptrConts {
                    .ptrConts__txt {
                        margin-bottom: 50px;

                        @include mbTab {
                            margin-bottom: 5%;
                        }
                    }

                    .ptrConts__list {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 50px;

                        @include tab {
                            margin: 0 -5% 10% 0;
                        }

                        @include mb {
                            margin: 0 -5% 15% 0;
                        }

                        .ptrConts__listImg {
                            @include mbTab {
                                padding-right: 5%;
                            }

                            img {
                                max-width: 110px;

                                @include mbTab {
                                    max-width: 70px;
                                }
                            }
                        }
                    }

                    .ptrConts__btn {
                        margin: 0 auto;
                    }
                }
            }
        }

        .home__tarte {
            position: relative;
            background: $color_4;

            .tarte {
                display: flex;
                align-items: center;

                @include mbTab {
                    display: block;
                }

                .tarte__img {
                    padding-right: 50px;

                    @include tab {
                        margin-bottom: 5%;
                        padding: 0 0 0 30px;
                        text-align: center;
                    }

                    @include mb {
                        margin-bottom: 10%;
                        padding: 0 0 0 30px;
                        text-align: center;
                    }

                    img {
                        max-width: 210px;

                        @include mbTab {
                            max-width: 173px;
                        }
                    }
                }

                .tarte__conts {
                    .tarte__contsTtl {
                        margin-bottom: 30px;

                        @include mbTab {
                            margin-bottom: 5%;
                            text-align: center;
                        }

                        img {
                            max-width: 238px;

                            @include mbTab {
                                max-width: 206px;
                            }
                        }
                    }

                    .tarte__contsTxt {
                        margin-bottom: 50px;

                        @include mbTab {
                            margin-bottom: 10%;
                        }
                    }

                    .tarte__contsBtn {
                        background: $color_3;
                        padding-right: 20px;

                        @include mbTab {
                            margin: 0 auto;
                        }

                        img {
                            max-width: 30px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
