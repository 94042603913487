@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?3oxzfo');
  src:  url('/assets/fonts/icomoon.eot?3oxzfo#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?3oxzfo') format('truetype'),
    url('/assets/fonts/icomoon.woff?3oxzfo') format('woff'),
    url('/assets/fonts/icomoon.svg?3oxzfo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shop:before {
  content: "\e90f";
}
.icon-heart:before {
  content: "\e90e";
}
.icon-truck:before {
  content: "\e90d";
}
.icon-minus:before {
  content: "\e90c";
}
.icon-arrow_l_h:before {
  content: "\e900";
}
.icon-arrow_l_l:before {
  content: "\e901";
}
.icon-arrow_l:before {
  content: "\e902";
}
.icon-arrow_r_h:before {
  content: "\e903";
}
.icon-arrow_r_l:before {
  content: "\e904";
}
.icon-batu:before {
  content: "\e905";
}
.icon-check:before {
  content: "\e906";
}
.icon-menu:before {
  content: "\e907";
}
.icon-pin:before {
  content: "\e908";
}
.icon-pintch:before {
  content: "\e909";
}
.icon-plus:before {
  content: "\e90a";
}
.icon-recycle:before {
  content: "\e90b";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-twitter:before {
  content: "\ea96";
}

// add style
.icon-arrow_b_h {
  display: inline-block;
  transform: rotate(-90deg);
}
.icon-arrow_b_h:before {
  content: "\e900";
}
