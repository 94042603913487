@charset "UTF-8";

/*-----------------------------------------------
 component
-----------------------------------------------*/
/*---- area  ----*/
.container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.contents {
    max-width: 900px;
    height: 100%;
    margin: 0 auto;
}

.content {
    padding:  80px 0 70px;

    @include tab {
        padding: 10% 5% 10%;
    }

    @include mb {
        padding: 15% 5% 12%;
    }
}

// header_boderLine分を調整
.contentTop {
    padding:  70px 0 80px;

    @include tab {
        padding: 10% 5%;
    }

    @include mb {
        padding: 12% 5%;
    }
}

.shopContent {
    padding: 50px 0;

    @include mbTab {
        padding: 10% 5%;
    }
}

/*---- button  ----*/
.stdBtn {
    display: block;
    max-width: 390px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $keyColor;
    border-radius: 6px;
    color: $baseColor;
    transition: .3s;

    @include mb {
        max-width: 100%;
        height: 50px;
    }

    &:hover {
        opacity: 0.9;
    }
}


/*---- box  ----*/
.kokuban--outer {
    position: relative;

    .kokuban {
        width: 100%;
        height: 300px;
        padding: 30px 50px 30px 30px;
        overflow: auto;
        border: 4px solid $color_1;
        box-shadow:0px 0px 6px 3px rgba(#000000, 0.4) inset;
        background: $keyColor url(/assets/images/bg_kokuban.png) repeat top center;

        @include mbTab {
            height: 235px;
            padding: 5% 50px 5% 5%;
        }

        .kokuban__deco {
            &.kokuban__deco--img {
                position: absolute;
                bottom: 6px;
                right: 46px;

                img {
                    max-width: 66px;

                    @include mbTab {
                        max-width: 50px;
                    }
                }
            }

            &.kokuban__deco--txt {
                position: absolute;
                right: 20px;
                color: $color_3;
                font-size: 1.2rem;
                writing-mode: vertical-rl;

                &.kokuban__deco--txtTop {
                    top: 30px;
                }

                &.kokuban__deco--txtBtm {
                    bottom: 30px;
                }
            }
        }
    }
}


// shopメニューの黒板
.shopBlkBd {
    position: relative;

    .shopBlkBd__boderTop {
        position: absolute;
        top: -4px;
        width: 100%;
        height: 58px;
        background: url(/assets/images/kokyo/kokuban-boderTop.png) repeat-x center;
    }

    .shopBlkBd__conts {
        margin: 0 20px;
        padding: 30px 50px;
        max-width: 910px;
        background: url(/assets/images/kokyo/kokuban-bg.png) repeat-y center;
        background-size: 100% auto;
        box-shadow:
            -8px 0px 12px -2px rgba(0,0,0,0.31) ,
            8px 0px 12px -2px rgba(0,0,0,0.31);

        @include mbTab {
            margin: 0 5%;
            padding: 30px 5%;
        }
    }

    .shopBlkBd__boderBtm {
        position: absolute;
        bottom: -32px;
        width: 100%;
        height: 58px;
        background: url(/assets/images/kokyo/kokuban-boderBtm.png) repeat-x center;
    }
}


/*---- font  ----*/
@import "font";


/*---- text  ----*/
.sizeS {
    font-size: $sizeS;
}

/*---- title  ----*/
.mainTtl {
    text-align: center;
    margin-bottom: 40px;

    img {
        width: auto;
        max-height: 28px;
    }

    @include tab {
        margin-bottom: 5%;
    }

    @include mb {
        margin-bottom: 8%;
    }
}

.shopTtl {
    position: relative;
    margin-bottom: 50px;
    padding-bottom: 94px;

    @include mbTab {
        margin-bottom: 50px;
        padding-bottom: 34px;
    }
    @include mb {
        margin-top: 5%;
    }

    .shopTtl__img {
        position: absolute;
        left: -210px;

        @include mbTab {
            left: -120px;
        }

        img {
            width: auto;
            max-height: 94px;

            @include mbTab {
                max-height: 54px;
            }
        }
    }
}

.shopPlace {
    position: absolute;
    top: -5px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 15px;

    @include mbTab {
        padding: 5% 5% 0 0;
    }

    .shopPlace__txt {
        padding-right: 8px;
        font-size: $sizeS;

        @include mbTab {
            order: 2;
            padding: 0 0 0 8px;
            font-size: 1.0rem;
        }
    }

    img {
        max-width: 30px;

        @include mbTab {
            max-width: 20px;
        }
    }
}

/*---- img ----*/
.cmsImg {
    width: 100%;
    max-width: 400px;
    height: 247px;
    background: $color_3 no-repeat center / cover;

    @include tab {
        max-width: 300px;
        height: 185px;
    }

    @include mb {
        max-width: 100%;
        height: 200px;
    }
}

/*---- deco ----*/
.boderImg {
    position: absolute;
    bottom: -15px;
    width: 100%;
    height: 25px;
    z-index: 1;

    &.boderImg--wht {
         background: url(/assets/images/img_line-wht.png) repeat-x center;
    }

    &.boderImg--be {
        background: url(/assets/images/img_line-be.png) repeat-x center;
    }

    &.boderImg--yel {
        background: url(/assets/images/img_line-yel.png) repeat-x center;
    }
}
