@charset "UTF-8";
/*-----------------------------------------------
 kokyo
-----------------------------------------------*/

.shopHeader {
    &.shopHeader--kokyo {
        .header__keyVs {
            background:
            url(../images/kokyo/header_keyVs-flame.png) no-repeat center / cover,
            url(../images/kokyo/header_keyVs.jpg) no-repeat center / cover;
        }
    }
}

.kokyo {
    &.kokyo--index {

        .kokyo__concept {

            .shopContent {
                position: relative;
            }

            .conceptTop {
                display: flex;
                justify-content: space-around;

                @include tab {
                    margin-bottom: 30px;
                    align-items: center;
                }

                @include mb {
                    display: inherit;
                    margin-bottom: 15%;
                }

                .catchCopy {
                    display: flex;

                    .catchCopy__img {
                        padding-right: 40px;

                        @include mb {
                            padding-right: 10%;
                        }

                        img {
                            max-width: 92px;

                            @include mbTab {
                                max-width: 48px;
                            }
                        }
                    }

                    .catchCopy__txt {
                        margin-top: 40px;

                        @include mbTab {
                            margin-top: 5%;
                        }
                    }
                }

                .cuisine {
                    position: relative;

                    @include mb {
                        margin-top: -30px;
                    }

                    // ブリと鯛
                    .cuisine__img {
                        text-align: right;

                        @include pc {
                            padding-bottom: 20px;
                        }

                        @include mbTab {
                            padding-bottom: 12%;
                        }

                        img {
                            max-width: 520px;

                            @include mbTab {
                                max-width: 294px;
                            }
                        }
                    }

                    .cuisine__name {
                        position: absolute;
                        bottom: 0;
                        right: 20px;

                        @include mb {
                            right: 2%;
                        }


                        img {
                            max-width: 280px;

                            @include mbTab {
                                max-width: 220px;
                            }
                        }
                    }
                }
            } //conceptTop

            .conceptBtm {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                @include tab {
                    justify-content:space-around;
                }

                @include mb {
                    display: block;
                    position: relative;
                    margin-bottom: 5%;
                }

                .cuisine {
                    display: flex;
                    align-items: center;

                    @include mb {
                        display: block;
                    }

                    // せんざんき
                    .cuisine__img {
                        margin-right: 30px;

                        @include mb {
                            display: inline-block;
                            margin: 0 0 5% 0;
                        }

                        img {
                            max-width: 440px;

                            @include mbTab {
                                max-width: 220px;
                            }
                        }
                    }

                    .cuisine__box {
                        @include mb {
                            padding-left: 5%;
                        }

                        .cuisine__boxName {
                            margin-bottom: 20px;

                            @include mb {
                                margin-bottom: 5%;
                            }

                            img {
                                max-width: 178px;


                                @include mbTab {
                                    max-width: 142px;
                                }
                            }
                        }
                    }
                }

                .catchCopy {
                    margin-bottom: 30px;
                    padding-right: 40px;

                    @include mb {
                        display: inline-block;
                        margin-bottom: 0;
                        padding-right: 5%;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }

                    img {
                        max-width: 120px;
                        @include mbTab {
                            max-width: 64px;
                        }
                    }
                }
            } //conceptBtm
        }

        .shop__menu--kokyo {
            .menu {
                .menu__deco {
                    position: absolute;
                    z-index: 1;

                    &.menu__deco--leftTop1 {
                        top: -30px;
                        left: 60px;

                        @include mbTab {
                            top: -6px;
                            left: 25px;
                        }

                        img {
                            max-width: 220px;

                            @include mbTab {
                                max-width: 110px
                            }
                        }
                    }

                    &.menu__deco--leftTop2 {
                        top: 60px;
                        left: -20px;

                        @include mbTab {
                            top: 40px;
                            left: -10px;
                        }

                        img {
                            max-width: 140px;

                            @include mbTab {
                                max-width: 70px;
                            }
                        }
                    }

                    &.menu__deco--rightTop1 {
                        top: -30px;
                        right: 40px;

                        @include mbTab {
                            top: -15px;
                            right: 20px;
                        }

                        img {
                            max-width: 190px;

                            @include mbTab {
                                max-width: 95px;
                            }
                        }
                    }

                    &.menu__deco--rightTop2 {
                        top: 100px;
                        right: -20px;

                        @include mbTab {
                            top: 50px;
                            right: -10px;
                        }

                        img {
                            max-width: 160px;

                            @include mbTab {
                                max-width: 80px;
                            }
                        }
                    }

                    &.menu__deco--leftBtm {
                        bottom: -60px;
                        left: 6px;

                        @include mbTab {
                            bottom: -30px;
                            left: 3px;
                        }

                        img {
                            max-width: 180px;

                            @include mbTab {
                                max-width: 90px;
                            }
                        }
                    }

                    &.menu__deco--rightBtm {
                        bottom: -80px;
                        right: -10px;

                        @include mbTab {
                            bottom: -40px;
                            right: -5px;
                        }

                        img {
                            max-width: 260px;

                            @include mbTab {
                                max-width: 130px;
                            }
                        }
                    }
                }
            }
        }

        .kokyo__info {

            .infoTop {
                .shopContent {
                    padding: 100px 0 50px;

                    @include mbTab {
                        padding: 16% 5% 10%;
                    }

                    @include tab {
                        padding: 10% 5% 5%;
                    }
                }

                .infoTop__deco--outer {
                    position: relative;

                    .infoTop__deco {
                        position: absolute;
                        z-index: 1;

                        &.infoTop__deco--leftTop1 {
                            position: absolute;
                            top: -40px;
                            right: 0;

                            @include tab {
                                right: 10px;
                            }

                            img {
                                max-width: 220px;

                                @include tab {
                                    max-width: 110px;
                                }

                                @include mb {
                                    display: none;
                                }
                            }
                        }

                        &.infoTop__deco--leftTop2 {
                            position: absolute;
                            top: 100px;
                            right: -40px;

                            @include tab {
                                top: 40px;
                                right: -20px;
                            }

                            img {
                                max-width: 150px;

                                @include tab {
                                    max-width: 75px;
                                }

                                @include mb {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .infoTop__detail {
                    overflow: hidden;

                    .infoTop__detailTtl {
                        float: left;
                        clear: both;
                    }

                    .infoTop__detailData {
                        margin-bottom: 20px;
                        padding-left: 90px;

                        @include mbTab {
                            padding-left: 70px;
                        }

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .infoMap {
                .shopContent {
                    padding: 50px 0 80px;

                    @include mbTab {
                        padding: 10% 5% 20%;
                    }

                    @include tab {
                        padding: 5% 5% 10%;
                    }
                }

                .infoMap__gmap {
                    width: 100%;
                    height: 460px;
                    position:relative;

                    @include mbTab {
                        height: 300px;
                    }
                }

                .infoMap__btn {
                    display: inline-block;
                    margin-bottom: 20px;
                    padding: 12px 26px;
                    border: 1px solid #fff;
                    border-radius: 4px;
                    background-color: transparent;

                    @include mb {
                        width: 100%;
                        margin-bottom: 10%;
                        padding: 10px 0;
                        text-align: center;
                    }

                    &:hover {
                        opacity: 1;
                        background-color: rgba(#fff, 0.2);
                    }
                }
            }
        }
    }
}

.shopFooter {
    &.shopFooter--kokyo {

        .shopFooter__board {
            background: url(../images/kokyo/footer_bg-kokyo.png) repeat center;

            .shopFooter__board--boderTop {
                background: url(../images/kokyo/footer_boderTop.png) repeat center;
            }

            .shopFooter__board--boderBtm {
                background: url(../images/kokyo/footer_boderBtm.png) repeat center;
            }
        }
    }
}
