@charset "UTF-8";

/*-----------------------------------------------
  NEWS
-----------------------------------------------*/
.header {
    &.header--news {
        background-image: url(../images/header_keyVs-news.jpg);
    }
}

.news {
    &.news--index {

        .news__cont {
            position: relative;

            .newsCard {
                overflow: hidden;
                margin-bottom: 50px;

                @include mb {
                    margin-bottom: 5%;
                }

                .newsCard__ttl {
                    margin-bottom: 30px;
                    font-size: 1.8rem;

                    @include mbTab {
                        margin-bottom: 5%;
                        font-size: 1.6rem;
                    }

                    @include tab {
                        margin-bottom: 20px;
                    }

                    .newsCard__ttlDeta {
                        display: block;
                        font-size: 1.4rem;
                        color: $color_3;
                    }
                }
                .newsCard__img {
                    float: left;
                    margin-right: 20px;

                    @include mb {
                        margin: 0 0 5% 0;
                    }
                }
            }

            .newsCard--btn {
                margin: 0 auto;
            }
        }
    }
}
