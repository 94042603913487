@charset "UTF-8";

/*-----------------------------------------------
  error
-----------------------------------------------*/

.header {
    &.header--error {
        background-image: url(../images/header_keyVs-error.jpg);

        .boderImg {
            bottom: -18px;
        }
    }
}

.error {
    .error__cont {
        position: relative;
        background: $color_2;

        .error__contTxt {
            margin-bottom: 60px;

            @include mb {
                margin-bottom: 15%;
            }
        }

        .error__contBtn {
            margin: 0 auto;
        }
    }
}
