@charset "UTF-8";

/*-----------------------------------------------
mixins
-----------------------------------------------*/
// @media
@mixin mb {
    @media screen and (max-width : 414px) and (orientation: portrait) {
        @content;
    }
}

@mixin mbTab {
    @media screen and (max-width : 768px) {
        @content;
    }
}

@mixin tab {
    @media screen and (min-width : 415px) and (max-width : 768px) {
        @content;
    }
}

@mixin tabPc {
    @media screen and (min-width : 415px) {
        @content;
    }
}

@mixin pc {
    @media screen and (min-width : 769px) {
        @content;
    }
}

@mixin md {
    @media screen and (min-width : 1000px) {
        @content;
    }
}

@mixin lg {
    @media screen and (min-width : 1380px) {
        @content;
    }
}

@mixin xlg {
    @media screen and (min-width : 1980px) {
        @content;
    }
}


// font
@mixin fontFamilySerif() {
    font-family: 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', '游明朝','Yu Mincho', '游明朝体', 'YuMincho','HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
}

@mixin fontFamilySansSerif() {
    font-family: 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ','Meiryo','游ゴシック','Yu Gothic','ＭＳ Ｐゴシック',sans-serif;
}

@mixin fontFamilyMetaGothic() {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
}

// accessory
@mixin placeholderColor($color) {
    &:placeholder-shown {
        color: $color;
    }

    &::-webkit-input-placeholder {
        color:$color;
    }

    &:-moz-placeholder {
        color:$color;
        opacity: 1;
    }

    &::-moz-placeholder {
        color:$color;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color:$color;
    }
}

// box
@mixin tableCol($verticalAlign) {
    display: table;

    & > * {
        float: none;
        display: table-cell;
        vertical-align: $verticalAlign;
    }
}

@mixin EqualWidthColumn($num) {
    margin-left: -$num;
    margin-right: -$num;

    & > * {
        padding: 0 $num;
    }
}

@mixin col_2($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(2n+1) {
        clear: both;
    }

    & > *:nth-child(n+3) {
        padding-top: ($num * 2);
    }
}

@mixin col_3($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(3n+1) {
        clear: both;
    }

    & > *:nth-child(n+4) {
        padding-top: ($num * 2);
    }
}

@mixin col_4($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(4n+1) {
        clear: both;
    }

    & > *:nth-child(n+5) {
        padding-top: ($num * 2);
    }
}

@mixin col_5($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(5n+1) {
        clear: both;
    }

    & > *:nth-child(n+6) {
        padding-top: ($num * 2);
    }
}

@mixin col_6($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(6n+1) {
        clear: both;
    }

    & > *:nth-child(n+7) {
        padding-top: ($num * 2);
    }
}

@mixin col_7($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(7n+1) {
        clear: both;
    }

    & > *:nth-child(n+8) {
        padding-top: ($num * 2);
    }
}

@mixin col_8($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(8n+1) {
        clear: both;
    }

    & > *:nth-child(n+9) {
        padding-top: ($num * 2);
    }
}

@mixin col_9($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(9n+1) {
        clear: both;
    }

    & > *:nth-child(n+10) {
        padding-top: ($num * 2);
    }
}

// fixed layout
@mixin rectangle4to3() {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 75% 0 0;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:  #b40d0d;
    }
}

@mixin rectangle16to9() {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 56.25% 0 0;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// margin / padding
@mixin margin($p, $m) {
    @include pc {
        margin: $p;
    }

    @include mbTab {
        margin: $m;
    }
}

@mixin marginTop($p, $m) {
    @include pc {
        margin-top: $p;
    }

    @include mbTab {
        margin-top: $m;
    }
}

@mixin marginLeft($p, $m) {
    @include pc {
        margin-left: $p;
    }

    @include mbTab {
        margin-left: $m;
    }
}

@mixin marginBottom($p, $m) {
    @include pc {
        margin-bottom: $p;
    }

    @include mbTab {
        margin-bottom: $m;
    }
}

@mixin padding($p, $m) {
    @include pc {
        padding: $p;
    }

    @include mbTab {
        padding: $m;
    }
}

@mixin paddingSide($p, $m) {
    @include pc {
        padding: 0 $p;
    }

    @include mbTab {
        padding: 0 $m;
    }
}

@mixin paddingVertical($p, $m) {
    @include pc {
        padding: $p 0;
    }

    @include mbTab {
        padding: $m 0;
    }
}

@mixin paddingTop($p, $m) {
    @include pc {
        padding-top: $p;
    }

    @include mbTab {
        padding-top: $m;
    }
}


@mixin paddingLeft($p, $m) {
    @include pc {
        padding-left: $p;
    }

    @include mbTab {
        padding-left: $m;
    }
}

@mixin paddingRight($p, $m) {
    @include pc {
        padding-right: $p;
    }

    @include mbTab {
        padding-right: $m;
    }
}

@mixin paddingBottom($p, $m) {
    @include pc {
        padding-bottom: $p;
    }

    @include mbTab {
        padding-bottom: $m;
    }
}

// component
@mixin fillTheGaps() {
    @include mbTab {
        padding-top: 10.4rem
    }

    @include mb {
        padding-top: 4.8rem;
    }
}
