@charset "UTF-8";

 /*-----------------------------------------------
 variable
 -----------------------------------------------*/

// color
$baseColor:    #fff;
$fontColor:    #000;
$keyColor:     #2e3f36;
$color_1:      #43251e;
$color_2:      #f5f3ef;
$color_3:      #f9a825;
$color_4:      #fff6dc;
$color_5:      #6b472f;
$color_san:    #2d355a;
$borderColor:  #7f7d76;
$inputColor:   #d3d3d4;
$linkColor:    #000;


// font size
$fontsizeBase: 62.5%; //font-size 1em = 10px on default browser settings
$sizeS: 1.2rem;
$sizeSS: 1rem;
$sizeM: 1.8rem;
$sizeL: 2.4rem;
$fontsizeSubTitle: $sizeL * .8;

// size
$pcNum: 8px;
$mbNum: 2.5%;
$radiusSize: 4px;

// url
$imgPath: "assets/images/"; //"http://";
